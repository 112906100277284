<template>
  <b-row>
    <b-col lg="12">
      <b-card header="General"
              header-text-variant="white"
              header-tag="header"
              header-bg-variant="dark">

        <b-row>
          <b-col lg="2">
            <b-form-group label="Logo">
              <Imagen :images="crud.form.image" @load-image="crud.form.image = $event" typeImage="Logo"/>
            </b-form-group>  
          </b-col>
          <b-col lg="10">
            <b-row>
              <b-col lg="6">
                <b-form-group label="Razón Social">
                  <b-form-input type="text"
                                size="sm"
                                v-model="crud.form.name">
                  </b-form-input>           
                </b-form-group>
              </b-col>

              <b-col lg="6">
                <FindObject render="search"
                            type="erpFiscalConditions" 
                            @select-object="loadFiscalConditions($event)" 
                            :valueID="crud.form.fiscal_conditions_id"                                        
                            :key="componentKey"/>   
              </b-col>

              <b-col lg="6">
                <FindObject render="search"
                            type="erpTypeDocuments" 
                            @select-object="loadTypeDocuments($event)" 
                            :valueID="crud.form.types_documents_id"
                            :key="componentKey"/>  
              </b-col>
                      
              <b-col lg="6">
                <b-form-group label="Número Documento">
                  <b-form-input type="text"
                                size="sm"
                                v-model="crud.form.documents_number">
                  </b-form-input>
                </b-form-group>
              </b-col>
                      
              <b-col lg="6">
                <b-form-group label="Email">
                  <b-form-input type="text"
                                size="sm"
                                v-model="crud.form.email">
                  </b-form-input>
                </b-form-group>
              </b-col>
                        
              <b-col lg="6">
                <b-form-group label="Teléfono">
                  <b-form-input type="text"
                                size="sm"
                                v-model="crud.form.phone">
                  </b-form-input>
                </b-form-group>
              </b-col>
                      
              <b-col lg="6">
                <b-form-group label="Inicio de Actividades">
                  <b-form-input type="date"
                                size="sm"
                                v-model="crud.form.date_start_activities">
                  </b-form-input>
                </b-form-group>
              </b-col>
              
              <b-col lg="6">
                <b-form-group label="Ingresos Brutos">
                  <b-form-input type="text"
                                size="sm"
                                v-model="crud.form.gross_receipts">
                  </b-form-input>
                </b-form-group>
              </b-col>
            </b-row>
          </b-col>
        </b-row>
      </b-card>
    </b-col>

    <b-col lg="12">
      <b-card header="Bancarios"
              header-text-variant="white"
              header-tag="header"
              header-bg-variant="dark">  
        <b-row>
          <b-col lg="6">
            <b-form-group label="CBU">
              <b-form-input type="text"
                            size="sm"
                            v-model="crud.form.cbu">
              </b-form-input>
            </b-form-group>
          </b-col>

          <b-col lg="6">
            <b-form-group label="Alias">
              <b-form-input type="text"
                            size="sm"
                            v-model="crud.form.alias">
              </b-form-input>
            </b-form-group>
          </b-col>                    
        </b-row>
      </b-card>
    </b-col>

    <b-col lg="12">
      <b-card header="Ubicación"
              header-text-variant="white"
              header-tag="header"
              header-bg-variant="dark">  

        <b-row>
          <b-col lg="8">
            <b-form-group label="Dirección">
              <b-form-input type="text"
                            size="sm"
                            v-model="crud.form.address">
              </b-form-input>           
            </b-form-group>
          </b-col>
                  
          <b-col lg="4">
            <b-form-group label="Código Postal">
              <b-form-input type="text"
                            size="sm"
                            v-model="crud.form.postal_code">
              </b-form-input>           
            </b-form-group>
          </b-col>

          <b-col lg="4">
            <b-form-group label="Ciudad">
              <b-form-input type="text"
                            size="sm"
                            v-model="crud.form.city">
              </b-form-input>           
            </b-form-group>
          </b-col>

          <b-col lg="4">
            <b-form-group label="Provincia">
              <b-form-input type="text"
                            size="sm"
                            v-model="crud.form.state">
              </b-form-input>           
            </b-form-group>
          </b-col>

          <b-col lg="4">
            <b-form-group label="País">
              <b-form-input type="text"
                            size="sm"
                            v-model="crud.form.country">
              </b-form-input>           
            </b-form-group>
          </b-col>
        </b-row>
      </b-card>
    </b-col>

    <b-col lg="12">                      
      <b-row>
        <b-col>
          <b-button type="button" variant="outline-dark" size="sm" @click="$router.go(-1)">
            <i class="fa fa-angle-double-left"></i>
            Volver
          </b-button>                        
        </b-col>              
        <b-col>
          <b-button variant="dark" size="sm" class="pull-right" @click="save()">Guardar</b-button>
        </b-col>
      </b-row>
    </b-col>
  </b-row>
</template>
<script>
  import serviceAPI from './services'
  import Error from '@/handler/error'
  import Session from '@/handler/session'
  import Profiles from '@/config/profiles'
  import Modules from '@/config/modules'
  import Helper from '@/handler/helper'    
  import FindObject from '@/components/inc/find/findObject'  
  import Imagen from '@/components/inc/image/image'

  export default {
    components: {
      FindObject,
      Imagen,
    },
    data() {
      return {
        crud: {
          form: {
            id: 1,
            name: '',
            address: '',
            city: '',
            postal_code: '',
            state: '',
            country: '',
            fiscal_conditions: null,
            fiscal_conditions_id: 0,
            types_documents: null,
            types_documents_id: 0,
            documents_number: 0,
            date_start_activities: '',
            gross_receipts: '',
            cbu: 0,
            alias: '',
            phone: '',
            email: '',
            image: [],
          }
        },
        componentKey: 0,
      }
    },
    mounted(){  
      this.load()                                 
    },
    methods: {            
      load() {
        var result = serviceAPI.mostrarEmpresa(this.crud.form.id)

        result.then((response) => {
          var data = response.data
          this.crud.form.id = data.id
          this.crud.form.name = data.name
          this.crud.form.address = data.address
          this.crud.form.city = data.city
          this.crud.form.postal_code = data.postal_code
          this.crud.form.state = data.state
          this.crud.form.country = data.country
          this.crud.form.documents_number = data.documents_number

          this.crud.form.fiscal_conditions = data.fiscal_conditions
          this.crud.form.fiscal_conditions_id = data.fiscal_conditions_id
          
          this.crud.form.types_documents = data.types_documents
          this.crud.form.types_documents_id = data.types_documents_id

          if(!data.date_start_activities){
            this.crud.form.date_start_activities = ''
          } else {
            this.crud.form.date_start_activities = data.date_start_activities
          }

          this.crud.form.gross_receipts = data.gross_receipts
          this.crud.form.cbu = data.cbu
          this.crud.form.alias = data.alias
          this.crud.form.phone = data.phone
          this.crud.form.email = data.email          

          this.crud.form.image = []    
          if(Array.isArray(data.image)) {
            this.crud.form.image = data.image
          } else {
            if(data.image!= undefined) {
              if(data.image.length > 0) {
                this.crud.form.image = [{ path: data.image }]
              } else {
                this.crud.form.image = []
              }
            }
          }

          this.forceRerender()
        })
        .catch(error => {
          this.$awn.alert(Error.showError(error))
        });
      },

      save() {
        if(this.crud.form.email) {
          var expreg = new RegExp("^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$")
          if (!expreg.test(this.crud.form.email)) {          
            this.$awn.alert("El email no posee un formato válido")          
            return false
          }       
        }

        let loader = this.$loading.show();                
        
        var image = new FormData();
        this.crud.form.image.forEach((value, key) => {
          image.append(key, value.path);
        })
        

        if(this.crud.form.id) {
          var result = serviceAPI.editarEmpresa(JSON.stringify(this.crud.form), image);
        }

        result.then((response) => {                    
          loader.hide()                    
          this.$awn.success("Datos guardados con éxito");
        })
        .catch(error => {
          loader.hide()
          this.$awn.alert(Error.showError(error));
        })
      },

      loadFiscalConditions (object) {
        if(object){
          this.crud.form.fiscal_conditions = object
          this.crud.form.fiscal_conditions_id = object.id             
        } else {
          this.crud.form.fiscal_conditions = null
          this.crud.form.fiscal_conditions_id = 0
        }
      },
      loadTypeDocuments (object) {
        if(object){
          this.crud.form.types_documents = object
          this.crud.form.types_documents_id = object.id             
        } else {
          this.crud.form.types_documents = null
          this.crud.form.types_documents_id = 0
        }
      },

      forceRerender() {
        this.componentKey += 1;  
      },
    }
  }
</script>
<style scoped>
  .card-header {
    padding: 1px;   
    padding-left: 5px;   
    font-weight: bold;     
  }
</style>